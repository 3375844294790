.c-page-content {
    
    div.no-group {
        text-align: center;
        padding:30px;
    }
    
    padding: 19px 0;
    position:relative;
    min-height:200px;
}


