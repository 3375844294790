.c-dot-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.c-dot-list__item {
    display: flex;
    align-items: baseline;
}

.c-dot-list__item-name {
    display: flex;
    flex: 1 1 0%;
    margin-right: .25em;
    margin-bottom: 0;

    &::after {
        content: '';
        border-bottom: 1px dotted #dfdfdf;
        flex: 1;
        margin-left: .5em;
        margin-bottom:4px;
    }
}

.c-dot-list__item-value {
    flex: 0 0 auto;
    text-align: right;
}
