.o-table {
    max-width: 100%;

    .caption {
        @extend h3;
        caption-side: top;
        margin-bottom: 6px;
        padding-top: 20px;
        padding-left:5px !important;
    }
    
    

    thead th {
        min-width: 80px;
        border-top: none;
        border-bottom: none;
        font-size: $font-size-sm;
        font-weight: 500;
        color: $primary;
        box-sizing: border-box;

        &:first-child {
            padding-left: 0;
        }

        &.sorting_asc,
        &.sorting_desc {
            font-weight: 700;
        }
    }

    tbody td {
        line-height: 23px;
        box-sizing: border-box;

        &:first-child {
            padding-left: 0;
        }
    }

    &--fixed {
        table-layout: fixed;
    }
}
