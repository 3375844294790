.c-select {
    width: 100%;
}

.c-select + .select2-container {
    display: block;
    margin-top: 0;
    border-radius: $border-radius;

    &:focus {
        outline: none;
        box-shadow: $input-focus-box-shadow;
    }
}

.c-select + .select2-container .select2-selection {
    min-height: 40px;
    border-color: $border-color;
}

.c-select + .select2-container .select2-selection .select2-selection__rendered {
    padding: 9px 13px 0;
    line-height: 1.5;
    color: $text-color;
}

.c-select + .select2-container .select2-selection.select2-selection--multiple .select2-selection__rendered {
    padding-top: 5px;
}

.c-select + .select2-container .select2-selection .select2-selection__choice {
    overflow: hidden;
    margin-top: 0;
    margin-right: 15px;
    background-color: $color-main;
    border: none;
    color: $white;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    border-radius: 2px;
}

.c-select + .select2-container .select2-selection.select2-selection--multiple .select2-selection__choice {
    margin-top: 4px;
}

.c-select + .select2-container .select2-selection .select2-selection__choice__remove {
    display: block;
    float: right;
    margin-right: 0;
    margin-left: 5px;
    color: $white;
}

.c-select + .select2-container .select2-search--inline .select2-search__field {
    margin-top: 3px;
    font-weight: 300;

    &::placeholder {
        color: $input-placeholder-color;
    }
}

.c-select + .select2-container .select2-selection--multiple .select2-search--inline .select2-search__field {
    width: 100% !important;
}

.c-select + .select2-container .select2-selection--single .select2-selection__arrow {
    height: 38px;
    width: 23px;

    b {
        left: 0;
        height: 12px;
        width: 12px;
        margin-top: -6px;
        margin-left: 0;
        border: none;
        background-image: url('../images/icons/arrow-down--blue.svg');
        background-size: 10px auto;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

.c-select + .select2-container.select2-container--open .select2-selection--single .select2-selection__arrow {
    b {
        transform: rotate(180deg);
    }
}
