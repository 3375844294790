
.c-btn--add-experiment.under-approval {
    bottom:84px;
}

.c-btn {
    @extend .btn;

    position: relative;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.20);

    &--primary {
        @extend .btn-primary;

        border-color: darken($primary, 8%);
    }

    &--link {
        padding: 0;
        margin: 0;
        border: none;
        color: $link-color;
        font-weight: 400;
        border-radius: 0;
        box-shadow: none;
    }

    &--add-new {
        font-weight: 500;

        .c-btn__icon {
            position: absolute;
            top: 50%;
            height: 24px;
            width: 24px;
            margin-top: -11px;
            margin-left: -4px;
            stroke: inherit;

            & + .c-btn__text {
                padding-left: 24px;
            }
        }
    }

    &--add-experiment {
        /*position: absolute;*/
        position: fixed;
        width: 52px;
        height: 52px;
        padding: 0;
        text-align: center;
        border-radius: 50%;
        box-shadow: 0 3px 6px rgba(0,0,0,0.3);
        right:20px;
        bottom:20px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            width: 29px;
            height: 4px;
            margin-top: -2px;
            background-color: currentColor;
            border-radius: 2px;
            transform-origin: 50% 50%;
        }

        &::before {
            transform: translateX(-50%);
        }

        &::after {
            transform: translateX(-50%) rotate3d(0,0,1,-90deg);
        }
    }

    &.disabled,
    &[disabled] {
        pointer-events: none;
    }

    &--processing {
        cursor: wait;
        pointer-events: none;
    }
}

.c-btn__text2 {
    font-weight:bold;
    padding-top: 2px;
}

.c-btn__icon,
.c-btn__text {
    display: inline-block;
    vertical-align: middle;
}

.c-btn__icon + .c-btn__text {
    padding-left: 0.25em;
}


.c-btn__icon2 {
    padding-top: 2px;
    padding-left: 2px;
}

.c-btn__icon3 {
    padding-top: 2px;
    padding-left: 0px;
    color:#399eff;
}




span.add-button {
    width: 18px;
    height: 18px;
    border-radius: 10px;
    color: white;
    background-color: #399eff;
    display: inline-block;
    margin-top: 3px;
    line-height: 0px;
    font-size: 16px;
    margin-right: 7.5px;
}


span.add-button2 {
    background-color:white;
    
}

.a-add-button:hover {
    text-decoration: none;
}