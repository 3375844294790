.c-clipboard {
    color: $color-main;
}

.c-clipboard__field {
    vertical-align: middle;
    width: calc(100% - 30px);
    max-width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    color: inherit;
    font-weight: 300;

    &:focus {
        outline: none;
    }
}

.c-clipboard__btn {
    margin-left: 11px;

    svg {
        width: 19px;
        height: 20px;
        vertical-align: top;
    }
}

.c-clipboard.is-invalid {
    color: $danger;
}

.is-invalid .c-clipboard__btn {
    display: none;
}
