.c-advanced-controls {
    display: flex;
}

.c-advanced-controls__item {
    margin-left: 36px;
}

.c-advanced-controls__btn {
    &:hover {
        color: $link-color;
    }
}

#getsharelink {
    padding:2px;
}

.c-advanced-controls__btn-icon {
    
    &.copy {
        width: 30px;
        height: 30px;
    }
    
    width: 18px;
    height: 18px;
    color: $link-color;
}

.c-advanced-controls__btn,
.c-advanced-controls__btn.disabled .c-advanced-controls__btn-icon {
    color: $text-color;
}

