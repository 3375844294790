.c-icon {
    display: inline-block;
    vertical-align: middle;
    height: 1em;
    width: 1em;
    fill: currentColor;
    stroke: currentColor;
}

.user-pic {
    width: 48px;
    height: 48px;
    border-radius: 24px;
}
