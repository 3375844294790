.c-left-bar {
    position: sticky;
    top: 76px;
    z-index: 1000;
    min-height: calc(100vh - 76px);
    flex: 0 0 260px;
    max-width: 260px;
    background-color: $gray-100;
    border-right: 1px solid $border-color;
}

.c-left-bar__nav {
    max-height: calc(100vh - 5rem);
    overflow-y: auto;
    padding-top: 20px;
    padding-bottom: 38px;
}

.c-left-bar__link {
    display: block;
    padding: 13px 15px 14px 60px;
    border-top: 18px solid $gray-100;
    line-height: 2;
    text-decoration: none;
    color: $text-color;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
        background: $gray-200;
        // border-top-color: $primary;
        color: $text-color;
        text-decoration: none;
    }
}

.c-left-bar__link-icon {
    height: 24px;
    width: 24px;
    margin-right: 5px;

    g {
        stroke: currentColor;
    }
}

.c-left-bar__item--active {
    font-weight: 700;

    .c-left-bar__link {
        background-color: #e1f0ff;
        // border-top-color: $gray-100;
    }

    .c-left-bar__link-icon {
        fill: $primary;
        stroke: $primary;
    }
}

.c-left-bar__link-icon,
.c-left-bar__link-text {
    vertical-align: middle;
}
