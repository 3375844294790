@import '~select2/src/scss/core';

.select2-container--open {
    margin-top: -1px;
}

.select2-dropdown {
    border-color: $border-color;
}

.select2-results__option {
    padding: 11px 18px 12px;

    // &:first-child {
    //     border-radius: 2px 2px 0 0;
    // }
}

.select2-container--open .select2-results__option--highlighted[aria-selected] {
    background-color: $color-main;
    color: $white;
}

.select2-container--open .select2-results__option[aria-selected=true] {
    padding-left: 36px;
    background-color: #e1f0ff;
    background-image: url('../images/icons/checkbox--blue.svg');
    background-size: 8px auto;
    background-repeat: no-repeat;
    background-position: 18px center;
    color: $text-color;
}

.select2-search--dropdown {
    padding: 0;
}

.select2-container .select2-search--dropdown .select2-search__field {
    padding: 12px 40px 12px 15px;
    background-color: $gray-100;
    background-image: url('../images/icons/search--blue.svg');
    background-size: 18px auto;
    background-repeat: no-repeat;
    background-position: 96% center;
    border: none;

    &:focus {
        outline: none;
    }
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: $input-placeholder-color;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    margin-right: 15px;
    vertical-align: top;
    font-size: 20px;
    line-height: 1;
    font-weight: normal;
    color: $color-main;
}
