.c-modal__header {
    position: relative;
    align-items: inherit;

    &::after {
        content: "";
        display: block;
        height: 1px;
        position: absolute;
        right: $modal-inner-padding;
        bottom: 0;
        left: $modal-inner-padding;
        background-color: $gray-200;
    }
}

.c-modal__title {
    font-size: $h3-font-size;
    font-weight: 300;
    text-transform: capitalize;
}

.c-modal__close {
    opacity: 1;
}

.c-modal__close svg {
    height: 20px;
    width: 20px;
}

.c-modal__body form {
    margin-top: 23px;
}

.c-modal__body .form-group--message {
    ul {
        margin: 0 0 0 1.25rem;
        padding: 0;
    }

    .alert {
        margin-bottom: 0;
    }
}

.c-modal__body .form-group {
   
    margin-bottom: 1rem;
     
    &.form-group--message > div {
        margin-bottom:5px;
    }
   
}




.c-modal__footer {
    justify-content: flex-start;
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: $gray-100;
    border: 1px solid $gray-200;

    & > :not(:first-child) {
        margin-left: 1.75rem;
    }
}

.c-modal__footer .c-btn--primary {
    min-width: 180px;
}

.c-modal--processing .modal-content {
    overflow: hidden;
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($white, 0.4);
        cursor: wait;
    }
}
