.c-datepicker {
    position: relative;
}

.c-datepicker__field {
    position: relative;
    z-index: 9999;
    background-color: transparent;

    &:focus {
        background-color: transparent;
    }
}

.c-datepicker__icon {
    position: absolute;
    top: 50%;
    right: $input-padding-x;
    z-index: 1;
    width: 17px;
    height: 19px;
    margin-top: -10px;
    color: $color-main;
}


.sprint_month_mark .ui-datepicker-calendar {
    display: none;
}

/*.ui-datepicker {
    margin-left: 100px;
    z-index: 1000;
    position:absolute !important;
}*/