/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Sep 23, 2019, 10:56:23 PM
    Author     : serviom
*/

.drag-and-drop {
    
    padding: 15px;
    background-color: #ECF5FF;
    border-radius: 10px;
    
}