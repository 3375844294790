.c-card {
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.05);
}

.c-card__body {
    padding-top: 25px;
    padding-bottom: 25px;
}

body.c-page--dashboard {
    .c-card__body {
        padding-bottom:25px;
    }
}
