.c-overlay {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 9998;
	display: none;
	background-color: $gray-900;
	opacity: .1;
	transition: all .3s ease-out;
}

body.overlay--show .c-overlay {
	display: block;
	opacity: .7;
}
