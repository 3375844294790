.c-burger {
    height: 14px;
    width: 20px;
    color: $link-color;

    &:hover {
        color: $link-hover-color;
    }

    &:focus {
        box-shadow: none;
    }

    svg {
        height: 100%;
        width: 100%;
        vertical-align: top;
    }
}
