.c-quick-comments__header {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid $gray-300;
}

.c-quick-comments__title {
    flex-grow: 1;
    margin-right: 10px;
}

.c-quick-comments__title-h {
    margin: 0;
}

.c-quick-comments__body {
    display: none;
    padding-top: 8px;
    padding-bottom: 8px;

    .is-collapsed & {
        display: flex;
    }
}

.c-quick-comments__item p {
    font-size: 18px;
    padding-top: 10px;
    font-family: "Helvetica";
}

.c-quick-comments__item h6 {
    font-family: "Helvetica";
    font-size: 12px;
    color: #000000;
    opacity: 0.5;
}

.c-quick-comments__item h3 {
    text-transform: none;
}

.c-quick-comments__item {
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid $gray-300;
}

.c-quick-comments__item-title,
.c-quick-comments__item-value {
    flex: 0 0 50%;
    max-width: 50%;
}

.c-quick-comments__item-value {
    text-align: right;
}

.c-quick-comments__toggle {
    flex-shrink: 0;
    width: 100%;
}

.c-quick-comments__toggle-text--collapse,
.c-quick-comments__toggle-icon--collapse {
    display: none;
}

.c-quick-comments__toggle-icon--expand {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.is-collapsed {
    .c-quick-comments__toggle-text--collapse,
    .c-quick-comments__toggle-icon--collapse {
        display: inline-block;
    }

    .c-quick-comments__toggle-text--expand,
    .c-quick-comments__toggle-icon--expand {
        display: none;
    }
}

.c-quick-comments__toggle-btn {
    color: #000000;
}

.c-quick-comments__item .media-body h5 {
    color: #000000;
    font-family: "GT Walsheim Pro Bold";
    font-size: 20px;
    text-transform: none;
}

.c-quick-comments__item label {
    text-transform: none;
    font-family: 'GT Walsheim Pro Light';
    font-size: 13px;
    color: gray;
}

.c-quick-comments__reply{
    background: none;
    border: 0;
}

.c-quick-comments__item p b{
    color: #1c6ca1;
}
