.c-navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    min-height: 74px;
    padding: $spacer 0;
    border-bottom: 1px solid $border-color;
    background-color: $white;
    box-shadow: 0 1px 3px $border-color;
}

.c-navbar__container {
    padding-right: 60px;
    padding-left: 60px;
}

.c-navbar__title {
    margin-bottom: 0;
}
