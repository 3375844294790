/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : Oct 1, 2019, 10:41:54 PM
    Author     : serviom
*/

.dropzone {
    .browser {
        color:#0078ec;
        text-decoration: underline;
        pointer-events: none;
    }
}

.icon-pasted {
    font-size: 24px;
    padding-bottom: 3px;
}


