@font-face {
    font-family: GT Walsheim Pro;
    src: local('GT Walsheim Pro Light'), local('GTWalsheimProLight'),
        url(../fonts/gtwalsheimpro-light-webfont.woff2) format('woff2'),
        url(../fonts/gtwalsheimpro-light-webfont.woff) format('woff');
    font-weight: 300;
    font-style: normal
}

@font-face {
    font-family: GT Walsheim Pro;
    src: local('GT Walsheim Pro Regular'), local('GTWalsheimProRegular'),
        url(../fonts/gtwalsheimpro-webfont.woff2) format('woff2'),
        url(../fonts/gtwalsheimpro-webfont.woff) format('woff');
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: GT Walsheim Pro;
    src: local('GT Walsheim Pro Medium'), local('GTWalsheimProMedium'),
        url(../fonts/gtwalsheimpro-medium-webfont.woff2) format('woff2'),
        url(../fonts/gtwalsheimpro-medium-webfont.woff) format('woff');
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: GT Walsheim Pro;
    src: local('GT Walsheim Pro Bold'), local('GTWalsheimProBold'),
        url(../fonts/gtwalsheimpro-bold-webfont.woff2) format('woff2'),
        url(../fonts/gtwalsheimpro-bold-webfont.woff) format('woff');
    font-weight: 700;
    font-style: normal
}
