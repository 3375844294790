.c-right-bar {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $white;
    box-shadow: inset 1px 0 0 0 $border-color;
    transition: right .2s ease;
}

body.right-bar--disabled .c-right-bar {
    right: -100%;
}

body.right-bar--editing .c-right-bar {
    width: 846px;
    max-width: 100%;
}

.c-right-bar__wrap {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.c-right-bar__content {
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 80px - 80px - 60px - 30px);
}

body.right-bar--editing .c-right-bar__content {
    padding-right: 0;
    padding-left: 0;
}

.c-right-bar__inner {
    padding-right: 60px;
    padding-left: 60px;
}

.c-right-bar__toggle {
    position: absolute;
    top: 55px;
    right: 56px;
    overflow: hidden;
    display: block;
    width: 27px;
    height: 27px;
    padding: 0;
    margin: 0;
    border: none;
    color: $primary;

    &:hover {
        color: $link-hover-color;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        height: 1px;
        width: 100%;
        background-color: currentColor;
        transform-origin: 50% 50%;
    }

    &::before {
        transform: translateX(-50%) rotate3d(0,0,1,45deg);
    }

    &::after {
        transform: translateX(-50%) rotate3d(0,0,1,-45deg);
    }
}

.c-right-bar__title {
    display: flex;
    align-items: flex-end;
    height: 80px;
    margin-bottom: 60px;
}

.c-right-bar__widget + .c-right-bar__widget {
    padding-top: 10px;
}

.c-right-bar__widget-title {
    margin-bottom: 3px;
}

.c-right-bar__footer {
    display: flex;
    align-items: center;
    height: 80px;
    margin-top: 30px;
    border-top: 1px solid $border-color;
}

.c-right-bar__loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: rgba($white, 0.9);
    opacity: 0;
    transition: $transition-fade;
}

.c-right-bar--processing .c-right-bar__loader {
    opacity: 1;
    z-index: 10;
    cursor: wait;
}

// Custom typo
.c-right-bar__content {
    ul {
        list-style-type: none;
        margin-left: 0;
        padding-left: 13px;

        ul, ol {
            padding-left: 1.5rem;
        }

        li {
            position: relative;

            &::before {
                position: absolute;
                left: -13px;
                top: 12px;
                content: '';
                display: block;
                height: 3px;
                width: 3px;
                margin-top: -2px;
                background-color: currentColor;
                border-radius: 50%;
            }
        }
    }

    ol {
        margin-left: 0;
        padding-left: 13px;

        ol, ul {
            padding-left: 1.5rem;
        }
    }

    > p {
        @include clearfix();
    }
}

body.mce-content-body {
    > p {
        @include clearfix();
    }
}
