.c-quick-results {
    margin-top: 2em;
}

.c-quick-results__header {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
   
}

.c-quick-results__title {
    flex-grow: 1;
    margin-right: 10px;
}

.c-quick-results__title-h {
    margin: 0;
}

.c-quick-results__item {
    display: none;
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid $gray-300;

    &:first-child,
    .is-collapsed & {
        display: flex;
    }
}

.c-quick-results__item-title,
.c-quick-results__item-value {
    flex: 0 0 50%;
    max-width: 50%;
}

.c-quick-results__item-value {
    text-align: right;
}

.c-quick-results__toggle {
    flex-shrink: 0;
}

.c-quick-results__toggle-text--collapse,
.c-quick-results__toggle-icon--collapse {
    display: none;
}

.is-collapsed {
    .c-quick-results__toggle-text--collapse,
    .c-quick-results__toggle-icon--collapse {
        display: inline-block;
    }

    .c-quick-results__toggle-text--expand,
    .c-quick-results__toggle-icon--expand {
        display: none;
    }
}
