.cro-mode {

    &.fancybox-show-thumbs .fancybox-inner {
        right: 0;
    }

    .fancybox-button {
        width: 64px;
        height: auto;
        background-color: transparent;

        svg path {
            fill: inherit;
            stroke-width: inherit;
        }
    }

    .fancybox-thumbs {
        top: auto;
        bottom: 40px;
        width: 100%;
        background-color: transparent;
    }

    .fancybox-thumbs__list {
        margin: auto;

        > a {
            margin: 5px 10px;
            max-width: calc(50% - 20px);
            font-size: 0;

            &.fancybox-thumbs-active {
                &::before {
                    border-color: $color-main;
                    border-width: 4px;
                }
            }
        }
    }
}
