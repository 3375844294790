body#tinymce {
    background-color: $white;
    margin: 10px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    letter-spacing: 0;
    text-transform: none;
}

h3, .h3 {
    text-transform: uppercase;
}

h4, .h4 {
    font-weight: 300;
    letter-spacing: 4.8px;
    text-transform: uppercase;
}

h5, .h5 {
    text-transform: uppercase;
}

img {
    max-width: 100%;
    height: auto;
}
