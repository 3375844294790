.c-create-table__edit,
.c-create-table__archive {
    display: flex;
    align-items: center;
    /*justify-content: flex-end;*/
    width: 100%;
    max-width: 100%;
}

.c-create-table__edit .c-icon,
.c-create-table__archive .c-icon {
    height: 18px;
    width: 18px;
}
