.c-page-header__top {
    padding: 15px 0 14px;
    border-bottom: 1px solid $border-color;
}

.c-page-header__descr {
    display: inline-block;
    padding: 0 0.5em;
    font-size: $font-size-sm;
    font-weight: 700;
    line-height: 1.692;
    text-transform: uppercase;
    background-color: #e1f0ff;
    border-radius: 2px;
}

.c-page-header__r {
    display: flex;
    flex-wrap: nowrap;
}

.c-page-header__dropdown {
    margin-left: 37px;
}

.c-page-header .navbar-brand {
    margin-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.c-page-header__container {
    position: relative;
}

@include media-breakpoint-up(lg) {
    .c-page-header .c-page-header__container {
        flex-wrap: nowrap;
    }
}

@include media-breakpoint-up(xl) {
    .c-page-header__container {
        padding-right: 60px;
        padding-left: 60px;
    }
}
