.c-bullet-list {
    margin: 0;
    padding: 5px 0 0 0;
    list-style-type: none;

    ul {
        list-style-type: none;
    }

    li {
        position: relative;
        padding-top: 7px;
        padding-left: 12px;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 15px;
            display: block;
            width: 4px;
            height: 4px;
            background-color: $color-main;
            border-radius: 50%;
        }
    }
}
