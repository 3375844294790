.c-detail-view__details {
    margin-top: 2em;
}

.c-quick-results .c-quick-results__body {
    
    height: 0px;
    overflow: hidden;

    .c-detail-view__details {
        margin-top:1em;
    }
    
}


.c-quick-results.is-collapsed .c-quick-results__body {
    height:auto;
    overflow: auto;
}


.c-quick-results__body .negative {
    color:red;
}

.c-quick-results__body .positive {
    color:#399eff;
}

.c-detail-view__details-subtitle {
    margin-bottom: 5px;
}

// Gallery
.c-detail-view__gallery {
    position: relative;
    margin: 1em 0 0;
    border-top: 1em solid $white;

    &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: -1em;
        width: 100%;
        height: 1px;
        background-color: $border-color;
    }
}

.c-detail-view__gallery-thumbs {
    
    figure {
        img {
           
            width: 100px;
        }
        
        /*height: 120px;*/
/*        display: inline-block;*/
        /*width: 120px;*/
        padding: 5px;
/*        border: 1px solid red;*/
/*        margin: 3px;*/
        box-shadow: 0 3px 6px 0 #0000000c;
        border: solid 1px #399eff;
        background-color: #ffffff;
    }
    
    display: flex;
    flex-wrap: wrap;
    margin: 5px -5px 0;

    &-item {
        /*flex: 0 0 calc(100% / 6);*/
        margin: 5px;
        background-color: $blue--light;
    }

    &-link {
        transition: opacity 250ms ease-out;
        &:hover {
            opacity: 0.6;
        }
    }

    
}

figcaption {
    display: none;
}

.c-detail-view__gallery--empty {
    &::after {
        display: block;
        content: "";
        padding-top: percentage(9 / 16);
    }
}

.c-detail-view__gallery-placeholder {
    background-color: $blue--light;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.c-detail-view__gallery-placeholder-text {
    position: absolute;
    top: 50%;
    left: 0;
    height: 24px;
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    margin-top: -12px;
    display: block;
    text-align: center;
    color: rgba($color-main, 0.4);
}


.copy-to-buffer {
    
    #postsharelink {
        width:500px;
    }
    
    display:none;
}


.c-page--test-detail {
    

    .o-result_direction {
        border-radius: 4px;
        box-shadow: 0 1px 2px 0 #0000000c;
        background-color: #79b79119;
        display:flex;
    }
    
    .c-btn--result {
        border-radius: 2px;
        text-transform: uppercase;
        font-size:18px;
        padding:5px 30px;
        float:left;
        font-weight: bold;
    }
    
    .c-btn--winner {
        background-color: #79b791;
        color:white;
    }
    
    .c-btn--other {
        background-color: #838584;
        color:white;
    }
    
    .info {
        font-family: GTWalsheimPro;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        float: left;
        padding: 10px 20px;
        margin-bottom: 0px;
    }
    
    .other {
        color:#838584;
    }
    
    .winner {
        color: #79b791;
    }
 
}

.c-primary-metric {
    border: 2px solid #e6e6e6;
    padding: 7px 0;
    border-left: 0px;
    border-right: 0px;
}

.c-primary-metric .c-dot-list__item-name::after {
    border-bottom:0px;
}

