.c-page--test-approval {
    
    
    .approve_launch_icon {
        background-image: url("/images/rocket.png");
        background-repeat: no-repeat;
        background-size: 14%;
        padding-left: 30px;
        line-height: 27px;
        background-position: left bottom;
    }
    
    .approve_build_icon {
        background-image: url("/images/build.png");
        background-repeat: no-repeat;
        background-size: 16%;
        padding-left: 30px;
        line-height: 30px;
        background-position: left bottom;
    }
    
    .c-approval-header-card {
        > div {
            background: transparent;
            border-width:0px;
        }
        background: transparent;
        border-width:0px;
    }
    
    .c-approval-header {
        
        padding-bottom: 40px;
        padding-top: 24px;
        color:white;
        background-color: $link-color;
        text-align:center;
        border-radius: 4px;
        box-shadow: 0 1px 2px 0 #0000000c;
        
        
/*  width: 1650px;

  height: 156px;

  border-radius: 4px;

  box-shadow: 0 1px 2px 0 #0000000c;

  background-color: #399eff;*/
        
        
        p {
            span {
                font-size:24px;
                font-weight:bold;
                text-transform: uppercase;
            }
            
            text-align:center;
            font-size:24px;
            
        }
        
        button {
            border: 1px solid white;
            font-size:15px;
            font-weight: bold;
            padding: 7px 25px;
        }
         
    }
}
