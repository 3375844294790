.c-dropdown__menu {
    max-height: calc(100vh - 100px);
    max-width: 18rem;
    overflow-y: auto;
    box-shadow: 0 10px 30px rgba($black, 0.4);

    .c-dropdown--clients & {
        min-width: 18rem;
    }

    .c-dropdown--settings & {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.c-dropdown__toggle {
    &::after {
        height: 12px;
        width: 12px;
        margin-left: 3px;
        border: none;
        background-image: url('../images/icons/arrow-down--blue.svg');
        background-size: 10px auto;
        background-repeat: no-repeat;
        background-position: center center;
        vertical-align: middle;
    }

    &--no-arrow {
        &::after {
            display: none;
        }
    }
}

.c-dropdown__toggle-text {
    @include text-truncate();

    display: inline-block;
    vertical-align: middle;
    max-width: 220px;
    color: $text-color;
}

.c-dropdown__item {
    width: 100%;
    padding-right: 20px;
    padding-left: 40px;
    font-weight: 300;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &.active {
        background-image: url('../images/icons/checkbox--blue.svg');
        background-position: 21px center;
        background-repeat: no-repeat;
        background-size: 12px auto;
    }

    .c-icon {
        .c-dropdown--settings & {
            color: $color-main;
        }
    }

    .c-dropdown--settings & {
        padding-top: 18px;
        padding-bottom: 18px;
        color: $white;
        cursor: pointer;
    }

    &:hover {
        .c-dropdown--settings & {
            background-color: rgba($color-main, 0.6);
            color: $white;
        }
    }

    &:last-child {
        .c-dropdown--settings & {
            border-top: 1px solid rgba($white, 0.2);
            color: #ff715b;
        }
    }
}

.c-dropdown__search-box {
    height: 35px;
    background-color: rgba($black, 0.3);
    border-color: $black;
    color: $gray-500;

    &:focus {
        background-color: rgba($black, 0.3);
        border-color: $black;
        color: $gray-500;
        box-shadow: 0 0 0 1px $black;
    }
}
