.c-page {
    &--settings {
        background-color: $white;
    }
}

body.c-page--dashboard .c-page__inner {
    padding-bottom:50px;
}

.c-page__inner {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    align-items: stretch;
}

.c-page__sidebar {
    min-width: 430px;
    max-width: 430px;
    flex: 0 0 430px;
}

.c-page__wrapper {
    min-height: 100vh;
    max-width: 100%;
    flex-grow: 1;
}

.c-page__sidebar + .c-page__wrapper {
    max-width: calc(100% - 430px);
    transition: margin .2s ease;
}

.c-page.right-bar--disabled .c-page__sidebar + .c-page__wrapper {
    max-width: 100%;
}

.c-page.right-bar--editing {
    overflow: hidden;
}

.c-page.right-bar--timymce-loaded:not(.right-bar--disabled) .c-page__wrapper {
    position: relative;
    z-index: 1;
    &::after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($gray-900, 0.9);
    }
}



.c-page--settings .dataTables_wrapper {
    table.js-datatables {
        min-width:auto;
        overflow-x:auto;
    }
}


.dataTables_wrapper {
    
    > div.row {
        overflow: hidden;
    }
    
    > div.row:nth-child(2) .col-sm-12 {
        overflow-x:auto;
    }
    
    table.js-datatables {
        min-width:1368px;
        overflow-x:auto;
    }
    
    div.col-lg-12 {
        
    }
   
}

/* sticky footer */
#sticky-footer {
    
    div.dataTables_wrapper div.dataTables_processing {
        opacity:0;
    }
    
    .page-item .page-link, .page-link:hover, .page-item:last-child .page-link {
        background:transparent;
    }
        
    .table th, .table td {
        border-bottom:2px solid #615961 !important;
        border-top-width:0px;
    }
    
    .sticky-footer-body {
        
        &.hidden {
            display:none;
        }
        
        .dataTables_wrapper > div.row:nth-child(2) .col-sm-12 {
            overflow-x:hidden;
        }
        
        .table.dataTable td.dataTables_empty, table.dataTable th.dataTables_empty {
            color: white;
        }
        
        td {
            color:white;
        }
        
        td:first-child {
            color:#399eff;
        }
        
        td:nth-child(5) {
            text-align:left;
            color:#399eff;
        }
        
    }
    
    .sticky-footer-header {
        
        border-bottom:2px solid #2f2b2f;
        
        .items-approvals {
            color:white;
            font-size:15px;
            padding-left:40px;
        }
        
        .first-col {
            text-align:left;
            
            .count {
                width: 20px;
                height: 20px;
                background-color: #ff715b;
                border-radius: 20px;
                margin-left: 10px;
                display: inline-block;
                padding: 0px 5px;
                font-size: 14px;
                font-weight: bold;
                color: white;
            }
        }

        .second-col > div {
            float:right;
        }
            
    }
        

    .toggle-sticky-footer {
        
        width: 16px;
        height: 16px;
        border-radius: 0px;
        background-color: #399eff;

        
        .inner {
            height: 16px;
            width: 16px;
            margin-left: 0px;
            border: none;
            background-image: url(/images/arrow-down--black.svg);
            background-size: 8px auto;
            background-repeat: no-repeat;
            background-position: center center;
            vertical-align: middle;
            cursor:pointer;
            
            &.close {
                background-image: url(/images/add.svg);
            }
        }
        
    }
}

.c-page.c-page--dashboard #sticky-footer {
    max-width: calc(100% - 430px);
}

.c-page.c-page--dashboard.right-bar--disabled #sticky-footer {
    max-width: 100%;
    z-index: 2;
}

body #sticky-footer {
    padding-bottom:0px !important;
    position: fixed;
    bottom: 0px;
    width: 100%;
}



.c-page--dashboard {
    .slider-attachments {
        
        li {
            height:80px;
            display:inline-block;
            width:80px;
            padding:5px;
            border:1px solid red;
            margin:3px;
            box-shadow: 0 3px 6px 0 #0000000c;
            border: solid 1px #399eff;
            background-color: #ffffff;
        }
        
        list-style:none;
    }
}
