// Fonts
@import 'fonts';

// Variables
@import 'variables';

// Vendor
@import '~bootstrap/scss/bootstrap';
@import '~datatables.net-bs4/css/dataTables.bootstrap4.min.css';
@import '~noty/src/noty.scss';
@import '~noty/src/themes/metroui.scss';
@import '~animate.css';
@import "~jquery-ui/themes/base/all.css";
@import "~@fancyapps/fancybox/dist/jquery.fancybox.css";
@import 'vendor/select2';
@import 'vendor/datatable';
@import 'vendor/fancybox';

// General
@import 'general/typo';
@import 'general/forms';

// Components
@import 'components/components';

// Objects
@import 'objects/objects.wrapper';
@import 'objects/objects.table';
